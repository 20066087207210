<template>
  <div>
    <normalPart :cont="cont"></normalPart>
  </div>
</template>

<script>
// @ is an alias to /src
import NormalPart from "../components/normalPart";
export default {
  name: "FirstPart",
  components: { NormalPart },
  data() {
    return {
      cont: {
        title: "教学数字化能力评测",
        desc:
          " 本评测旨在考核被评测者在网络培训中在线教学和数字工具使用水平，考察被评测者对在线教学和数字教学辅助工具如PowerPoint、Photoshop、Premiere等常用的数字化教学软件的掌握程度。通过本项评测应用推广，能提升被测评者对在线教学和数字化工具重要性的认识，提升整个测评者的线上执业能力。",
        target:
        `本评测主要适用人员是从事在线教育培训领域工作，并致力于在网络培训领域获得进一步发展的人员，帮助他们获得相关领域能力的标准评定。本评测同时也适用于教育培训企业对从业员工的招聘与管理，做为其相关技能水平的评价依据。
         评测内容主要围绕实际在线教学及线上教学应用能力展开，内容涵盖在线教学中“在线辅导教学”“在线直播课”以及“在线录播课”的教学考核，通过在线答题的形式和描述数字教学工具的具体功能及效果，考察候选人对在线教学和数字教学工具的掌握程度，考察的软件工具包括PPT、WPS、Adobe Photoshop、Premiere、Camtasia Studio、会声会影。评测的内容不涉及复杂的理论内容，一切围绕在线教学和软件的实际使用技能应用展开。`,
        methods: `教学数字化考试要求参与者以在线答题的方式进行，共100道选择题，用时约90分钟，满分100分。被评测者在参加评测期间应该遵守考试纪律，独立完成评测内容，请勿违反考试规则。`,
        symble: [
            `本评测模块试题分两大部分，“在线辅导教学”和“数字教学工具”，其中在线教学包括“在线辅导教学”、“在线直播课”以及“在线录播课”，数字教学工具涉及包括金山WPS演示、微软PowerPoint、Adobe Photoshop，以及Premiere Pro、Camtasia Studio、会声会影等辅助软件工具。`,
            `（1）在线教学（占40%）<br />
            1.在线辅导教学<br />
            2.在线直播课<br />
            3.在线录播课<br />
            4.网络教学的控制与保障<br />
            <br />
            （2）金山WPS演示及Microsoft PowerPoint 软件（占30%）<br />
            1.构建清晰易懂的演示结构<br />
            2.制作图文并茂的幻灯片<br />
            <br />
            （3）Adobe Photoshop 软件  （占10%）<br />
            1.Photoshop软件基本功能<br />
            2.Photoshop操作方法<br />
            <br />
            （4）视频剪辑软件 (占20%),在Premiere、Camtasia Studio、会声会影中任选一个：<br />
            - Adobe Premiere Pro<br />
            1.影视编辑基本概念<br />
            2.Adobe Premiere Pro工作流程<br />
            3.Adobe Premiere Pro基本操作使用方法<br />
            <br />
            - Camtasia Studio<br />
            1.Camtasia Studio 基本功能<br />
            2.Camtasia Studio 基本操作使用方法<br />
            <br />
            - 会声会影<br />
            1.软件功能特点<br />
            2.软件的主要操作使用方法<br />
            <br />
         `,
        ],
      },
    };
  },
};
</script>
<style lang="less" scoped></style>
